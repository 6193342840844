import React from "react";
import { Link, navigate, StaticQuery, graphql } from "gatsby";
import { getUser, isLoggedIn, logout } from "../services/auth";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { indigo, orange } from "@mui/material/colors";

export default function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  let greetingMessage = "";
  if (isLoggedIn()) {
    greetingMessage = `Hello ${getUser().name}`;
  } else {
    greetingMessage = "You are not logged in";
  }

  const mainMenu = [
    {
      link: "/app/recipes",
      text: "Recipes",
    },
    {
      link: "/app/ingredients",
      text: "Ingredients",
    },
    {
      link: "/app/mealplans",
      text: "Meal Plans",
    },
    {
      link: "/app/calendar",
      text: "Calendar",
    },
  ];

  return (
    <StaticQuery
      query={graphql`
        query {
          strapiGlobal {
            siteName
          }
          allStrapiCategory {
            edges {
              node {
                slug
                name
              }
            }
          }
        }
      `}
      render={(data) => (
        <AppBar
          position="static"
          enableColorOnDark
          sx={{ backgroundColor: orange[800] }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
              >
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(`/`);
                  }}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {data.strapiGlobal.siteName}
                </Button>
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {mainMenu.map((s, i) => {
                  return (
                    <Button
                      key={"menu" + i}
                      onClick={(event) => {
                        event.preventDefault();
                        navigate(s.link);
                      }}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      {s.text}
                    </Button>
                  );
                })}
              </Box>

              {/* /// RESPONSIVE */}
              <Box sx={{ flexGrow: 0, display: { md: "none", xs: "flex" } }}>
                <Tooltip title="Open settings">
                  <IconButton
                    color="inherit"
                    onClick={handleOpenNavMenu}
                    sx={{ p: 0 }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                >
                  {mainMenu.map((s, i) => {
                    return (
                      <Button
                        key={"menu2" + i}
                        onClick={(event) => {
                          event.preventDefault();
                          navigate(s.link);
                        }}
                        sx={{ my: 2, display: "block" }}
                      >
                        {s.text}
                      </Button>
                    );
                  })}
                </Menu>
              </Box>

              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: { md: "none", xs: "flex" }, flexGrow: 1 }}
              >
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(`/`);
                  }}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {data.strapiGlobal.siteName}
                </Button>
              </Typography>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={getUser().name}
                      // src="/images/favicon.png"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Button
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/profile`);
                    }}
                    sx={{ my: 2, display: "block" }}
                  >
                    Profile
                  </Button>

                  {isLoggedIn() && (
                    <Button
                      onClick={(event) => {
                        event.preventDefault();
                        logout(() => navigate(`/app/login`));
                      }}
                      sx={{ my: 2, display: "block" }}
                    >
                      Logout
                    </Button>
                  )}
                  {/* {data.allStrapiCategory.edges.map((category, i) => (
                    <MenuItem
                      key={`category__${category.node.slug}`}
                      onClick={handleCloseNavMenu}
                    >
                      <Typography textAlign="center">
                        <Link to={`/category/${category.node.slug}`}>
                          {category.node.name}
                        </Link>
                      </Typography>
                    </MenuItem>
                  ))} */}
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      )}
    />
  );
}
